<template>
  <PageLayout
    :title="$t('loginsuccess.title')"
  >
    <p class="login-success__paragraph">
      <span class="login-success__text">
        {{ $t('loginsuccess.subtitle') }}
      </span>
    </p>
    <base-button
      :customizable="{ maxWidth: '350px' }"
      :is-type="['simple', 'vanish']"
      data-testid="open-voicemod-button"
      :is-disabled="isButtonDisabled"
      @onClick="onClickAction"
    >
      {{ $t('loginsuccess.button') }}
    </base-button>
  </PageLayout>
</template>

<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router/composables';
import { mapActions } from 'vuex';
import { AuthClient } from '@/services/models/authentication';
import { mParticleMetric } from '@/metrics';
import PageLayout from '@/components/PageLayout/PageLayout.vue';
import BaseButton from '@RepoComponent/BaseButton/BaseButton';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import * as Sentry from '@sentry/vue';

export default {
    components: {
        PageLayout,
        BaseButton,
    },

    setup() {
        const route = useRoute();
        const isButtonDisabled = ref(false);
        const vmSoundboardCode = ref(null);
        const userId = route.query.userId;
        const isNew = route.query.isNew;
        const action = route.query.action;

        return {
            isButtonDisabled,
            vmSoundboardCode,
            userId,
            isNew,
            action,
        };
    },

    async mounted() {
        this.axn_setShowProfile(true);
        if (this.userId) {
            this.vmSoundboardCode = await this.getVMSoundboardCode();
        }
    },

    methods: {
        ...mapActions('user_info_store', [
            'axn_setShowProfile',
        ]),
        getDeeplinkEnv() {
            if (this.action === LOGIN_ACTIONS_CONSTANTS.VM_SOUNDBOARD_DEV) {
                return 'vmsoundboarddev';
            }

            if (this.action === LOGIN_ACTIONS_CONSTANTS.VM_SOUNDBOARD_STA) {
                return 'vmsoundboardsta';
            }

            return 'vmsoundboard';
        },
        onClickAction() {
            this.isButtonDisabled = true;

            window.location.href = `${this.getDeeplinkEnv()}://login?code=${this.vmSoundboardCode}&userId=${this.userId}&isNew=${this.isNew}`;
        },

        async getVMSoundboardCode() {
            try {
                const { data } = await AuthClient.generateThirdPartyCode(this.userId, LOGIN_ACTIONS_CONSTANTS.VMGO);
                return data.code;
            } catch (error) {
                Sentry.captureMessage('Can\'t generate third party code for VM-SOUNDBOARD');
                Sentry.captureException(error);
                mParticleMetric.track(mParticleMetric.EVENTS.ACCOUNTS_WEB_UNEXPECTED_ERROR_OCCURRED, {
                    login_error_reason: 'vm_soundboard_third_party_code_generation_failed',
                });
                this.$router.push(ROUTER_PATHS.ACCOUNT_ACTIVATED_ERROR);
            }
        },
    },
};
</script>

<style src="./VMSoundboardLoginSuccessView.scss" lang="scss" />
